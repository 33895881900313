import { StytchB2BProvider } from '@stytch/nextjs/b2b'
import { createStytchB2BHeadlessClient } from '@stytch/nextjs/b2b/headless'

const stytch = createStytchB2BHeadlessClient(process.env.STYTCH_PUBLIC_TOKEN, {
  cookieOptions: {
    availableToSubdomains: true,
    domain: process.env.STYTCH_COOKIE_DOMAIN,
    opaqueTokenCookieName: 'ancho_session',
    jwtCookieName: 'ancho_session_jwt',
  },
})

const AnchoApp = ({ Component, pageProps }) => {
  return (
    <StytchB2BProvider stytch={stytch}>
      <Component {...pageProps} />
    </StytchB2BProvider>
  )
}

export default AnchoApp
